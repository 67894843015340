@import url(https://fonts.googleapis.com/css?family=Poppins:regular,bold,italic&subset=latin,latin-ext);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: rgb(247, 214, 72);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cloudamite-slogan-top{
  background-color:transparent;
  height: 50px;
  position:fixed;
  top:10px;
  overflow:hidden;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto; 
  right: 0;
  left: 0;
}
.cloudamite-slogan{
  margin: 0 auto;
  height: 50px;
  display: inline-block;
  overflow: hidden;
  background-color: transparent;
}
.cloudamite-slogan#img{
  width:100%;
}

.cloudamite-logo-footer{
  margin-top: 30px;
  background-color: transparent;
  height: 50px;
  position:absolute;
  bottom: 10px;
  overflow:hidden;
  white-space: nowrap;
  max-width: 1000px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto; 
  right: 0;
  left: 0;
}

.logo-footer-left{
  background-color:black;
  height: 50px;
  display: inline;
  float: left;
  position:absolute;
  top:0;
  left:0;
}
.logo-footer-left#img{
  width:100%;
}
.logo-footer-right{
  height: 50px;
  display: inline-block;
  overflow: hidden;
  background-color: black;
}

.logo-footer-right#img{
  width:100%;
  display: block;
}
.gamecontainer{
  background-color: transparent;
  position:fixed;
  overflow:hidden;
  white-space: nowrap;
  max-width: 1000px;
  margin-top: 120px;
  margin-left: auto;
  margin-right: auto; 
  right: 0;
  left: 0;
}

.gameboard{
  display: grid;
  grid-template-columns: repeat(3, 120px);
  grid-template-rows: repeat(3, 70px);
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.extrabuttoncontainer {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.newBingoSheetButton{
  width: 120px;
  height: 70px;
  margin: 0 auto;
  background-color: transparent;
  color: rgb(255, 255, 255);
  border-color: rgb(247, 214, 72);
  font-family: Poppins;
}

.gameButton {
  border-color: rgb(247, 214, 72);
  background: rgb(0,0,0);
  color: rgb(255, 255, 255);
  font-family: Poppins;
  font-size: 16px;
  display: grid;
  justify-items: center;
  align-items: center;
}

.gameButton > * {
  grid-row: 1;
  grid-column: 1;
}

.gameButton > .gameButtonX {
  display: none;
}

.pressedGameButton > .gameButtonX {
  display: block;
}

.gameButtonX {
  color: rgb(247, 214, 72);
  font-weight: bold;
  font-size: 40px;
}

@media only screen and (max-width: 500px) {
  .cloudamite-slogan-top{
    background-color:transparent;
    height: 50px;
    position:relative;
    top:10px;
    overflow:hidden;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    max-width: 500px;
  }
  .cloudamite-slogan{
    margin: 0 auto;
    height: 50px;
    display: inline-block;
    overflow: hidden;
    background-color: transparent;
  }
  .cloudamite-slogan#img{
    width:100%;
  }
  
  .cloudamite-logo-footer{
    margin-top: 30px;
    background-color: transparent;
    height: 50px;
    position:absolute;
    bottom: 10px;
    overflow:hidden;
    white-space: nowrap;
    max-width: 500px;
    justify-content: center;
    
  }
  
  .logo-footer-left{
    background-color:black;
    height: 50px;
    display: inline;
    float: left;
    position:absolute;
    top:0;
    left:0;
  }
  .logo-footer-left#img{
    width:100%;
  }
  .logo-footer-right{
    height: 50px;
    display: inline-block;
    overflow: hidden;
    background-color: black;
  }
  
  .logo-footer-right#img{
    width:100%;
    display: block;
  }
  .gamecontainer{
    background-color: transparent;
    position:relative;
    overflow:hidden;
    white-space: nowrap;
    max-width: 500px;
    margin-top: 60px;
  }
}

@media only screen and (max-height: 400px) {
    .cloudamite-logo-footer{
      display: none;
  }
  
}

.kirosanabingo {
  background-color: rgb(0,0,0);
}

#particles-js {
  position : absolute;
  width:100%;
  height:100%;
  z-index:-1;
}
