
.kirosanabingo {
  background-color: rgb(0,0,0);
}

#particles-js {
  position : absolute;
  width:100%;
  height:100%;
  z-index:-1;
}